import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index.js'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'tasks',
      component: () => import('@/views/Tasks.vue'),
      props: { type: 'Tasks'},
      meta: {
        pageTitle: 'Tareas pendientes',
        requiresAuth: true
      },
    },    
    {
      path: '/closedTasks',
      name: 'closedTasks',
      component: () => import('@/views/ClosedTasks.vue'),
      props: { type: 'Tasks'},
      meta: {
        pageTitle: 'Tareas cerradas',
        requiresAuth: true 
      },
    }, 
    
    //hacerlo
    {
      path: '/newTask',
      name: 'newTask',
      component: () => import('@/views/CreateTask.vue'),
      props: { type: 'NewTask'},
      meta: {
        pageTitle: 'Crear nueva tarea',
        requiresAuth: true 
      },
    },    
    {
      path: '/newTasks',
      name: 'newTasks',
      component: () => import('@/views/NewTasks.vue'),
      props: { type: 'Tasks'},
      meta: {
        pageTitle: 'Tareas nuevas',
        requiresAuth: true 
      },
    },    
    {
      path: '/edit-task/:task',
      name: 'task',
      component: () => import('@/views/Task.vue'),
      props: { type: 'EditTask'},
      meta: {
        requiresAuth: true 
      },
    },
    {
      path: '/create-report/:task',
      name: 'createReport',
      component: () => import('@/views/CreateReport.vue'),
      meta: {     
        requiresAuth: true 
      },
    },
    {
      path: '/create-budget/:task',
      name: 'createBudget',
      component: () => import('@/views/CreateBudget.vue'),
      meta: {     
        requiresAuth: true 
      },
    },
    {
      path: '/create-charge/:budget',
      name: 'createCharge',
      component: () => import('@/views/CreateCharge.vue'),
      meta: {     
        requiresAuth: true 
      },
    },
    // {
    //   path: '/report/:budget',
    //   name: 'editReport',
    //   component: () => import('@/views/Report.vue'),
    //   meta: {     
    //     requiresAuth: true 
    //   },
    // },
    {
      path: '/budget/:budget',
      name: 'editBudget',
      component: () => import('@/views/Budget.vue'),
      meta: {     
        requiresAuth: true 
      },
    },
    
   
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})
router.beforeEach(async (to, from, next) => {
  await store.dispatch("auth/getUser")
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters['auth/currentUser']) {
      next();
      return;
    }
    next("/login");
  } else {
    next();
  }
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
